<template>
  <el-row style="width: 250px">
    <el-col :class="$langConfig.cssClass" style="width: 180px">
      <dg-text-field
          :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
          :field_span="13"
          :label_span="11"
          :translateLabel='false'
          field_mark="required"
          label="Business #"
          placeholder="12345-1234"
          :liveUpdate="true"
          @amUpdated='(p, val) => {part1Updated(p)}'
          maxLength="10"
          name="business_number"
          rules="required"></dg-text-field>
    </el-col>
    <el-col :class="$langConfig.cssClass" style="width: 70px">
      <dg-text-field
          style="margin-top: 0px;width: 100%"
          defaultValue="RT0001"
          :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
          :field_span="23"
          :label_span="1"
          @amUpdated='(p, val) => {checkDublication()}'
          :liveUpdate="true"
          :translateLabel='false'
          field_mark=""
          label=""
          name="business_number_code"
          maxLength="6"
          placeholder="RT0001"
          rules=""></dg-text-field>
    </el-col>
  </el-row>
</template>

<script>
export default {
  beforeMount() {
    this.originalNumber = this.control.dataModel.business_number + " " + this.control.dataModel.business_number_code
  },
  data () {
    return {
      originalNumber: null,
      currentNumber: '',
      currentCode: ''
    }
  },
  methods: {
    checkDublication () {
      this.control.checkDuplicateFields(false, false)
    },
    emitUpdate() {
      this.$emit('updated', this.getFullValue())
    },
    updateNumber (val) {
      this.currentNumber = val
      this.emitUpdate()
    },
    updateCode (val) {
      this.currentCode = val
      this.emitUpdate()
    },
    getFullValue() {
      return this.currentNumber + ' ' + this.currentCode
    },
    mask (value) {
      let output = [];
      let len = value.length
      let d = false
      for(let i = 0; i < value.length; i++) {
        if( i !== 0 && (i % 5 === 0 && len > 5 && !d) ) {
          d = true
          output.push("-"); // add the separator
        }
        output.push(value[i]);
      }
      return output.join("")
    },
    unmask (val) {
      return val.replace(new RegExp(/[^\d]/, 'g'), ''); // Remove every non-digit character
    },
    part1Updated (props) {
      let value = props.field.field_value
      value = this.unmask(value)
      props.field.field_value =  this.mask(value);
      this.checkDublication()
    }
  },
  props: {
    control: {
      required :true
    },
    editMode: {
      required :true
    }
  }
}
</script>
